import { getJsMpegUrl } from '../../../common/utils/vcamHelper';

export const isCamera = (item) => item.category === 'vcam';
export const isCorrectCamera = (item) => (
  item.attributes?.categoryParams?.videoRecordService && item.attributes?.categoryParams?.cameraName);

export const getNameDisplayed = (videoItem) => `${videoItem.attributes.categoryParams.cameraName} (${videoItem.name})`;
export const getCameraId = (videoItem) => `${videoItem.uniqueId}${videoItem.id}`;

export const createCameraMetadata = (item) => {
  const { name, id } = item;
  const { cameraName, videoRecordService } = item.attributes.categoryParams;
  const cameraId = getCameraId(item);
  const nameDisplayed = getNameDisplayed(item);
  const jsmpegStream = getJsMpegUrl(item);

  return {
    id: cameraId,
    deviceId: id,
    deviceName: name,
    cameraName,
    videoRecordService,
    nameDisplayed,
    jsmpegStream,
  };
};

export const formatCameraDate = (datetime) => datetime.format('DD.MM.YYYY HH:mm:ss');
export const formatCameraDatetime = (datetime) => datetime.format('dd DD.MM.YYYY HH:mm:ss');

export const createCameraNameWithTime = (cameraName, from, to) => `${cameraName} ${formatCameraDate(from)} - ${formatCameraDate(to)}`;

export const createVideoUrlForM3U8 = (videoItem) => `${videoItem.videoRecordService}/vod/${videoItem.camera}/start/${videoItem.start_time}/end/${videoItem.end_time}/index.m3u8`;
