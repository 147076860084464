import React, { useState, useEffect } from 'react';

const LoadingText = ({ text, speed = 500 }) => {
  const [displayText, setDisplayText] = useState(`${text}   `);

  useEffect(() => {
    let dotCount = 0;
    const intervalId = setInterval(() => {
      dotCount = (dotCount + 1) % 4;
      setDisplayText(text + '.'.repeat(dotCount) + '   '.slice(dotCount));
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return <span>{displayText}</span>;
};

export default LoadingText;
