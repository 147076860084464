const config = {
  availableCategories: [
    'знаки',
    'транспорт',
    'устройства',
    'фигуры',
    'военные',
  ],
};

const icons = {
  военные: {
    бункер_1: { canRotate: false },
    взрыв_1: { canRotate: false },
    подводка_1: { canRotate: false },
    пушка_1: { canRotate: false },
    пушка_2: { canRotate: false },
    ракета_1: { canRotate: false },
    самолет_1: { canRotate: true },
    самолет_2: { canRotate: true },
    самолет_3: { canRotate: false },
    солдаты_1: { canRotate: false },
    танк_1: { canRotate: false },
    танк_2: { canRotate: false },
    танк_3: { canRotate: false },
    торпеда_1: { canRotate: false },
  },

  знаки: {
    бег_1: { canRotate: false },
    внимание_1: { canRotate: false },
    вопрос_1: { canRotate: false },
    люди_1: { canRotate: false },
    навигатор_1: { canRotate: true },
    поУмолчанию_1: { canRotate: false },
    птица_1: { canRotate: false },
    птицы_1: { canRotate: false },
    рыба_1: { canRotate: false },
    собака_1: { canRotate: true },
    сообщение_1: { canRotate: false },
    цель_1: { canRotate: false },
    человек_1: { canRotate: false },
  },

  транспорт: {
    автобус_1: { canRotate: false },
    автомобиль_1: { canRotate: false },
    автомобиль_2: { canRotate: false },
    велосипед_1: { canRotate: false },
    вертолет_1: { canRotate: false },
    вертолет_2: { canRotate: false },
    грузовик_1: { canRotate: false },
    грузовик_2: { canRotate: false },
    джип_1: { canRotate: false },
    коптер_1: { canRotate: false },
    коптер_2: { canRotate: false },
    корабль_1: { canRotate: false },
    корабль_2: { canRotate: false },
    кран_1: { canRotate: false },
    лодка_1: { canRotate: false },
    мотоцикл_1: { canRotate: false },
    поезд_1: { canRotate: false },
    самолет_1: { canRotate: true },
    самолет_2: { canRotate: true },
    скутер_1: { canRotate: false },
    трактор_1: { canRotate: false },
    трамвай_1: { canRotate: false },
    троллейбус_1: { canRotate: false },
  },

  устройства: {
    камера_1: { canRotate: true },
    камера_2: { canRotate: false },
    камера_3: { canRotate: false },
    камера_4: { canRotate: false },
    камера_5: { canRotate: false },
    пульт_1: { canRotate: false },
    радар_1: { canRotate: false },
    радар_2: { canRotate: false },
    радио_1: { canRotate: false },
    станция_1: { canRotate: false },
    станция_2: { canRotate: false },
    телефон_1: { canRotate: false },
    телефон_2: { canRotate: false },
    телефон_3: { canRotate: false },
    телефон_4: { canRotate: false },
    телефон_5: { canRotate: false },
    телефон_6: { canRotate: false },
  },

  фигуры: {
    высадка_1: { canRotate: false },
    звезда_1: { canRotate: false },
    иерархия_1: { canRotate: false },
    квадрат_1: { canRotate: false },
    квадрат_2: { canRotate: false },
    квадрат_3: { canRotate: true },
    квадрат_4: { canRotate: false },
    круг_1: { canRotate: false },
    прямоугольник_1: { canRotate: false },
    пятиугольник_1: { canRotate: false },
    точка_1: { canRotate: false },
    точка_2: { canRotate: false },
  },
};

// Функция для фильтрации доступных категорий
const getAvailableIcons = (categories, icons) => {
  const availableIcons = {};
  categories.forEach((category) => {
    if (icons[category]) {
      availableIcons[category] = icons[category];
    }
  });
  return availableIcons;
};

const availableIcons = getAvailableIcons(config.availableCategories, icons);
export default availableIcons;
