/* eslint-disable no-await-in-loop */
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import { useRef } from 'react';
import {
  createVideoUrlForM3U8, formatCameraDatetime,
} from '../utils/cameraRecord';
import CameraLayer from './CameraLayer';
import { getThumbUrl } from '../utils/eventReviews';
import { isUndefinedOrNull } from '../../../common/utils/stringUtils';

const EventReviewLayer = ({
  cameraLayerRef = undefined, eventReviewLimit = undefined,
}) => {
  const cameraLayer = isUndefinedOrNull(cameraLayerRef) ? CameraLayer({}) : cameraLayerRef;

  const eventReviews = useRef([]);

  const getEventReviews = () => [...eventReviews.current];

  const clearResources = () => {
    eventReviews.current = [];
  };

  const loadEventReviews = async () => {
    clearResources();

    await cameraLayer.loadCameras();

    const videoRecordServices = cameraLayer.getVideoRecordServices();

    if (videoRecordServices.size === 0) {
      return;
    }

    const filteredStrings = [];
    const reviewsIds = new Set();

    for (const videoRecordService of videoRecordServices.values()) {
      try {
        let url = `${videoRecordService}/api/review`;
        if (!isUndefinedOrNull(eventReviewLimit)) {
          const query = new URLSearchParams({
            limit: eventReviewLimit,
          });
          url = `${videoRecordService}/api/review?${query.toString()}`;
        }
        const response = await fetch(url);

        const reviews = (await response.json()).map((rev) => {
          const seconds = 20;
          rev.videoRecordService = videoRecordService;
          rev.thumbUrl = getThumbUrl(rev);

          if (isUndefinedOrNull(rev.start_time) && isUndefinedOrNull(rev.end_time)) {
            rev.start_time = moment().unix();
            rev.end_time = moment().unix() + seconds;
          } else if (isUndefinedOrNull(rev.start_time)) {
            rev.start_time = rev.end_time - seconds;
          } else if (isUndefinedOrNull(rev.end_time)) {
            rev.end_time = rev.start_time + seconds;
          }

          rev.cameraText = rev.camera;

          rev.datetimeText = formatCameraDatetime(moment.unix(rev.start_time));
          rev.text = `${rev.cameraText} ${rev.datetimeText}`;
          rev.videoUrl = createVideoUrlForM3U8(rev);

          return rev;
        });

        if (!Array.isArray(reviews)) {
          continue;
        }

        const allowedCameraNames = cameraLayer.getAllowedCameraNames();
        for (const review of reviews) {
          if (reviewsIds.has(review.id)) {
            continue;
          }
          reviewsIds.add(review.id);

          if (allowedCameraNames.some((allowedName) => allowedName.includes(review.camera))) {
            filteredStrings.push(review);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (filteredStrings.length === 0) {
      return;
    }

    filteredStrings.sort((a, b) => b.start_time - a.start_time);
    eventReviews.current = filteredStrings;
  };

  return {
    loadEventReviews, getEventReviews,
  };
};

export default EventReviewLayer;
