import React, { useState } from 'react';
import 'video.js/dist/video-js.css';
import { makeStyles } from '@material-ui/core';
import { Popup } from 'devextreme-react';
import ControlsPanel from './ControlsPanel';

const useStyles = makeStyles(() => ({
  removePaddings: {
    margin: -20,
  },
}));

const VideoPlayerJsMPEG = ({
  url, label, responsive,
}) => {
  const classes = useStyles();

  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleSwitchScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  if (isFullScreen) {
    return (
      <Popup
        visible
        defaultHeight="auto"
        defaultWidth="85%"
        showTitle={false}
        showCloseButton={false}
        resizeEnabled={false}
        dragEnabled={false}
        position="center"
        shadingColor="rgba(80 80 80 / 10%)"
        closeOnOutsideClick={false}
        onHiding={handleSwitchScreen}
      >
        <div className={classes.removePaddings}>
          <ControlsPanel
            url={url}
            label={label}
            isFullScreen={isFullScreen}
            onFullScreen={handleSwitchScreen}
          />
        </div>
      </Popup>
    );
  }

  return (
    <ControlsPanel
      responsive={responsive}
      url={url}
      label={label}
      isFullScreen={isFullScreen}
      onFullScreen={handleSwitchScreen}
    />
  );
};

export default VideoPlayerJsMPEG;
