import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button, ButtonGroup, Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import RemoveDialog from './common/components/RemoveDialog';
import { useTranslation } from './common/components/LocalizationProvider';
import { devicesActions, tailActions } from './store';
import { getIsAdmin, getPosition } from './common/utils/selectors';
import { getCategory } from './common/utils/formatter';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    boxShadow: 'none',
    zIndex: 2,
  },
  menuEditRemove: {
    zIndex: -1,
    position: 'relative',
  },
  stickyTop: {
    top: theme.spacing(9),
    right: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      top: theme.spacing(3),
      right: theme.spacing(3),
    },
  },
}));

const EditCollectionView = ({
  content,
  editPath,
  endpoint,
  disableAdd,
  handleClose,
  setSaveMode,
  onTail,
  sortValue,
  setCommandsPanelDevice,
  progress,
  searchName,
  mainPage,
  forseAdd,
  textOfAddButton,
  stickyTop,
  hideButtonAdd,
  removeEnable,
  panel,
  setPositionsBS,
  setMoveableDevice,
}) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const isAdmin = useSelector(getIsAdmin);

  const [selectedId, setSelectedId] = useState(null);
  const [item, setItem] = useState(null);
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);

  const closedDevices = useSelector((state) => state.devices.closedDevices);
  const devicesParams = useSelector((state) => state.devices.itemsParams);
  const tailSelectedDevices = useSelector((state) => state.tail.selectedDevices);
  const tailSelectedCategories = useSelector((state) => state.tail.selectedCategories);
  const adminEnabled = useSelector((state) => state.session.user && state.session.user.administrator);
  const position = useSelector(getPosition(item?.id));
  const device = useSelector((state) => state.devices.items[position?.deviceId]);

  const isDesktopAndHaveText = useMediaQuery(theme.breakpoints.up('lg')) && textOfAddButton;

  const menuShow = (anchorId, itemId, item) => {
    setSelectedId(itemId);
    setItem(item);
    if (anchorId) {
      const rect = anchorId.getBoundingClientRect();
      setMenuPosition({ top: rect.bottom, left: rect.left });

      setSelectedAnchorEl(anchorId);
      setIsMenuOpen(true);
    } else {
      setRemoveDialogShown(true);
    }
  };

  const menuHide = () => {
    setSelectedAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleAdd = () => {
    history.push(editPath);
    menuHide();
  };

  const handleEdit = () => {
    if (endpoint === 'geofences') {
      setSaveMode(selectedId);
    } else {
      history.push(`${editPath}/${selectedId}`);
    }
    menuHide();
  };

  const handleMove = async () => {
    setMoveableDevice((prevState) => {
      if (prevState?.deviceId === selectedId) {
        return (null);
      }
      return {
        deviceId: selectedId,
      };
    });
    menuHide();
  };

  const handleRemove = () => {
    setRemoveDialogShown(true);
    menuHide();
  };

  const handleCommands = () => {
    setCommandsPanelDevice(item);
    menuHide();
  };

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    setUpdateTimestamp(Date.now());
  };

  const changeVisibleDevice = () => {
    if (!closedDevices[position.deviceId]) {
      dispatch(devicesActions.hide(position.deviceId));
      dispatch(tailActions.changeState({ [position.deviceId]: false }));
    } else {
      dispatch(devicesActions.open(position.deviceId));
      dispatch(tailActions.changeState({ [position.deviceId]: true }));
    }
    menuHide();
  };

  const changeVisibleIconText = (val) => {
    setUpdateTimestamp(Date.now());
    dispatch(devicesActions.changeItemParam({ [position.deviceId]: { iconText: val } }));
  };

  const changeTail = (val) => {
    setUpdateTimestamp(Date.now());
    dispatch(tailActions.changeDevice({ [position.deviceId]: val }));
    if (val === false || (val === null && tailSelectedCategories[getCategory(device ?? item)] === false)) {
      dispatch(tailActions.remove([position.deviceId]));
    } else {
      dispatch(tailActions.changeState({ [position.deviceId]: true }));
    }
  };

  const getIconTextButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (devicesParams[position?.deviceId]?.iconText ?? null) === val ? '#deefd8' : '',
  });

  const getTailButtonStyle = (val) => ({
    margin: 0,
    backgroundColor: (tailSelectedDevices[position?.deviceId] ?? null) === val ? '#deefd8' : '',
  });

  const Content = content;

  return (
    <>
      <Content
        updateTimestamp={updateTimestamp}
        onMenuClick={menuShow}
        handleClose={handleClose}
        onTail={onTail}
        sortValue={sortValue}
        progress={progress}
        searchName={searchName}
        onAddNewRow={handleAdd}
        panel={panel}
        setPositionsBS={setPositionsBS}
      />
      {!!((adminEnabled || forseAdd) && !disableAdd && !hideButtonAdd) && (
        <Fab
          className={`${classes.fab} ${stickyTop && classes.stickyTop}`}
          title={textOfAddButton}
          variant={isDesktopAndHaveText ? 'extended' : 'circular'}
          size="small"
          color="default"
          onClick={handleAdd}
        >
          <AddIcon />
          {isDesktopAndHaveText && (textOfAddButton)}
        </Fab>
      )}

      {useMemo(() => (
        <>
          {mainPage ? (
            <Menu
              anchorEl={selectedAnchorEl}
              anchorReference="anchorPosition"
              anchorPosition={menuPosition}
              open={!!selectedAnchorEl}
              onClose={menuHide}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              className={classes.menuEditRemove}
            >
              {!!(setMoveableDevice && isAdmin) && (
                <MenuItem onClick={() => handleMove()}>{t('sharedMove')}</MenuItem>
              )}
              <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
              <MenuItem onClick={() => changeVisibleDevice()} disabled={!position || !!progress}>
                {!closedDevices[position?.deviceId] ? t('sharedHide') : t('sharedShow')}
              </MenuItem>

              <MenuItem disabled={!position || !!progress} onClick={() => { history.push(`/replay?deviceId=${position?.deviceId}`); }}>{t('sharedShowRoute')}</MenuItem>

              <MenuItem disabled={!position || !!progress} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ButtonGroup fullWidth variant="outlined" size="small">
                  <Button size="small" style={getIconTextButtonStyle(null)} onClick={() => changeVisibleIconText(null)}>
                    <Typography variant="caption">{t('auto')}</Typography>
                  </Button>
                  <Button size="small" style={getIconTextButtonStyle(true)} onClick={() => changeVisibleIconText(true)}>
                    <TextFieldsIcon style={{ color: '#198cff' }} size="small" />
                  </Button>
                  <Button size="small" style={getIconTextButtonStyle(false)} onClick={() => changeVisibleIconText(false)}>
                    <TextFieldsIcon size="small" />
                  </Button>
                </ButtonGroup>
              </MenuItem>
              <MenuItem disabled={!position || !!progress} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ButtonGroup fullWidth variant="outlined" size="small">
                  <Button size="small" style={getTailButtonStyle(null)} onClick={() => changeTail(null)}>
                    <Typography variant="caption">{t('auto')}</Typography>
                  </Button>
                  <Button size="small" style={getTailButtonStyle(true)} onClick={() => changeTail(true)}>
                    <img
                      src="/images/icon/switch/tail-1.svg"
                      alt=""
                    />
                  </Button>
                  <Button size="small" style={getTailButtonStyle(false)} onClick={() => changeTail(false)}>
                    <img
                      src="/images/icon/switch/tail-off.svg"
                      alt=""
                    />
                  </Button>
                </ButtonGroup>
              </MenuItem>
              {(device?.phone ?? item?.phone) && (
                <MenuItem onClick={handleCommands}>{t('commandSendSms')}</MenuItem>
              )}
            </Menu>
          ) : (
            <Menu
              open={!!selectedAnchorEl}
              anchorEl={selectedAnchorEl}
              onClose={menuHide}
              className={classes.menuEditRemove}
            >
              <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
              {(adminEnabled || removeEnable) && (
                <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
              )}
            </Menu>
          )}
        </>
      ), [isMenuOpen, updateTimestamp])}
      <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId} onResult={handleRemoveResult} />
    </>
  );
};

export default EditCollectionView;
