/* eslint-disable no-empty-pattern */
import { useCallback, useRef } from 'react';
import 'react-calendar-timeline/lib/Timeline.css';
import { createCameraMetadata, isCamera, isCorrectCamera } from '../utils/cameraRecord';

const CameraLayer = ({ }) => {
  const videoRecordServices = useRef(new Set());
  const uniqueIds = useRef(new Set());
  const allowedCameraNames = useRef([]);
  const camerasMetadata = useRef({});

  const getVideoRecordServices = () => new Set(videoRecordServices.current);
  const getCameraUniqueIds = () => new Set(uniqueIds.current);
  const getAllowedCameraNames = () => [...allowedCameraNames.current];
  const getCamerasMetadata = () => ({ ...camerasMetadata.current });

  const clearResources = () => {
    videoRecordServices.current = new Set();
    uniqueIds.current = new Set();
    allowedCameraNames.current = [];
    camerasMetadata.current = {};
  };

  const loadCameras = useCallback(async () => {
    clearResources();

    const response = await fetch('/api/devices');
    const data = await response.json();

    for (const item of Object.values(data)) {
      if (!isCamera(item)) {
        continue;
      }

      if (isCorrectCamera(item)) {
        const cameraMetadata = createCameraMetadata(item);

        camerasMetadata.current[cameraMetadata.id] = { ...cameraMetadata };
        uniqueIds.current.add(cameraMetadata.id);

        videoRecordServices.current.add(cameraMetadata.videoRecordService);
        allowedCameraNames.current.push(cameraMetadata.nameDisplayed);
      }
    }
  }, []);

  return {
    loadCameras, getVideoRecordServices, getCameraUniqueIds, getAllowedCameraNames, getCamerasMetadata,
  };
};

export default CameraLayer;
