import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(7),
  },
  rootMoved: {
    right: theme.spacing(56),
  },
  container: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export const ContainerLocationEnum = Object.freeze({
  TopRight: 'topRight',
});

const IconsContainer = ({
  children,
  needMoveMap,
  containerLocation = ContainerLocationEnum.TopRight,
}) => {
  const classes = useStyles();

  const getClass = () => {
    if (containerLocation === ContainerLocationEnum.TopRight) {
      return `${classes.root} ${needMoveMap && classes.rootMoved}`;
    }
    return '';
  };

  return (
    <div className={getClass()}>
      <div className={classes.container}>
        {children}
      </div>
    </div>
  );
};

export default IconsContainer;
