import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dvr } from '@material-ui/icons';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import CustomizableBadge from '../../../common/components/CustomizableBadge';
import { universalPanelActions } from '../../../store/universalPanel';
import { availableComponents } from '../../../common/hooks/useComponentsController';
import useStylesForUniversalPanelOfPerfectTable from '../../Settings/common/useStylesForUniversalPanelOfPerfectTable';
import { isUndefinedOrNull } from '../../../common/utils/stringUtils';

const UniversalPanelButton = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const accessibleCategoriesByDetected = useSelector((state) => state.devices.accessibleCategoriesByDetected);
  const categories = useSelector((state) => state.devices.categories);
  const panelState = useSelector((state) => state.universalPanel);

  const shouldShowUniversalPanelButton = ['detected_object', 'radar', 'anti_uav', 'vcam'].some((category) => categories.hasOwnProperty(category));

  const listTables = {
    detected_object: availableComponents.DetectedObjectsPage,
    radar: availableComponents.RadarsPage,
    anti_uav: availableComponents.AntiUavsPage,
  };

  const universalPanelSettings = {
    header: availableComponents.SelectCategory,
    settings: {
      disableStickyHeader: true,
      disableHeader: true,
      classes: useStylesForUniversalPanelOfPerfectTable(),
      label: t('deviceCategoriesDetected_object'),
      linkText: 'settings/detected',
      disableFilterRowTool: true,
      disableSpeedColumn: true,
      disableCoordinateColumn: true,
    },
  };

  useEffect(() => {
    if (accessibleCategoriesByDetected.length > 0
      && isUndefinedOrNull(localStorage.getItem('selectedObjectCategoryOnMap'))) {
      universalPanelSettings.body = listTables[accessibleCategoriesByDetected[0]];
    }
  }, [accessibleCategoriesByDetected]);

  const handleClick = () => {
    if (panelState.isShow || panelState.header) {
      dispatch(universalPanelActions.changeVisibility());
    } else {
      dispatch(universalPanelActions.createAndShow(universalPanelSettings));
    }
  };

  if (!shouldShowUniversalPanelButton) {
    return (<></>);
  }

  return (
    <CustomizableBadge
      isActive={panelState.isShow ?? false}
      title={t('globalObjectDetectedPanel')}
      icon={<Dvr />}
      onClick={handleClick}
    />
  );
};

export default UniversalPanelButton;
