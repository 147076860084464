/* eslint-disable no-await-in-loop */
import 'react-calendar-timeline/lib/Timeline.css';
import { useRef } from 'react';
import CameraLayer from './CameraLayer';
import { getImgUrl, getVideoUrl } from '../utils/exportRecord';
import { isUndefinedOrNull } from '../../../common/utils/stringUtils';

const ExportLayer = ({
  cameraLayerRef = undefined,
}) => {
  const cameraLayer = isUndefinedOrNull(cameraLayerRef) ? CameraLayer({}) : cameraLayerRef;

  const exportedVideos = useRef([]);

  const getExportedVideos = () => [...exportedVideos.current];

  const clearResources = () => {
    exportedVideos.current = [];
  };

  const loadExports = async () => {
    clearResources();

    await cameraLayer.loadCameras();

    const videoRecordServices = cameraLayer.getVideoRecordServices();

    if (videoRecordServices.size === 0) {
      return;
    }

    const filteredStrings = [];
    const exportsIds = new Set();

    for (const videoRecordService of videoRecordServices.values()) {
      try {
        const response = await fetch(`${videoRecordService}/api/exports`);
        const exportedVideos = (await response.json()).map((v) => {
          v.videoRecordService = videoRecordService;
          v.videoUrl = getVideoUrl(v);
          v.imgUrl = getImgUrl(v);
          return v;
        });

        if (!Array.isArray(exportedVideos)) {
          continue;
        }

        const allowedCameraNames = cameraLayer.getAllowedCameraNames();
        for (const video of exportedVideos) {
          if (exportsIds.has(video.id)) {
            continue;
          }
          exportsIds.add(video.id);

          if (allowedCameraNames.some((allowedName) => video.name.includes(allowedName))) {
            filteredStrings.push(video);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (filteredStrings.length === 0) {
      return;
    }

    filteredStrings.sort((a, b) => b.date - a.date);
    exportedVideos.current = filteredStrings;
  };

  return {
    loadExports, getExportedVideos,
  };
};

export default ExportLayer;
