import React from 'react';
import { useSelector } from 'react-redux';
import MarkerMovingModeOn from './MarkerMovingModeOn';
import TrackingModeOn from './TrackingModeOn';
import NewMessages from './NewMessages';
import UpdatingNeedableButton from './UpdatingNeedableButton';
import EventNotifications from './EventNotifications';

const NotificationButtons = ({
  moveableMarker, setMoveableMarker, newSms, needMoveMap, notificationsDataVisible, setNotificationsDataVisible,
}) => {
  const socketOpened = useSelector((state) => state.session.socketOpened);
  const deviceSelected = useSelector((state) => state.devices.selectedId);
  const events = useSelector((state) => state.events.items);

  return (
    <>
      {moveableMarker && (
        <MarkerMovingModeOn moveableMarker={moveableMarker} setMoveableMarker={setMoveableMarker} needMoveMap={needMoveMap} />
      )}
      {!!newSms.length && (
        <NewMessages newSms={newSms} />
      )}
      {!socketOpened && (
        <UpdatingNeedableButton />
      )}

      {deviceSelected && (
        <TrackingModeOn deviceSelected={deviceSelected} />
      )}
      {(!!events.length || notificationsDataVisible) && (
        <EventNotifications events={events} notificationsDataVisible={notificationsDataVisible} setNotificationsDataVisible={setNotificationsDataVisible} />
      )}
    </>
  );
};

export default NotificationButtons;
