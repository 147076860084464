import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  formatSpeed, formatDate, formatPosition, formatBoolean, collectReport, abortRequest,
} from '../../common/utils/formatter';
import { useAttributePreference, usePreference } from '../../common/utils/preferences';
import { useTranslation } from '../../common/components/LocalizationProvider';
import usePersistedState from '../../common/utils/usePersistedState';
import logout from '../../common/utils/logout';
import ReportTemplate from './components/ReportTemplate';
import PositionsMap from '../../map/PositionsMap';
import positionsTypes from '../../common/static/positionsTypes';
import MapCamera from '../../map/MapCamera';
import Map from '../../map/Map';
import useReportStyles from './hooks/useReportStyles';
import { runLoadingResources } from '../../map/MapControllerDynamic';
import { errorsActions } from '../../store';
import { getCategoryName, tryConvertAndFixedNumber } from '../../common/components/SmartTable/funcs/calculateCellValue';
import { isUndefinedOrNull } from '../../common/utils/stringUtils';

// Имя ключа в localStorage со списком выключенных колонок
const typeReport = 'routeReport';

// Список колонок [имя поля в json с бэкенда, имя поля в файле переводчике, возможность сортировки (0 - нет, 1 - да)]
const columnsArray = [
  ['deviceId', 'reportDevice', 1],
  // Столбец категории
  ['categoryFromPosition', 'reportCategory', 1],
  ['group', 'reportGroup', 1],
  ['serverTime', 'reportServerTime', 1],
  ['fixTime', 'reportFixTime', 1],
  ['latitude', 'reportLatitude', 0],
  ['longitude', 'reportLongitude', 0],
  ['altitude', 'globalAltitude', 0],
  ['address', 'reportAddress', 0],
  ['valid', 'reportValid', 0],
  ['speed', 'reportSpeed', 0],
  ['sat', 'reportSat', 0],
  ['mccMnc', 'reportMccMnc', 0],
  ['lacCid', 'reportLacCid', 0],
  ['visibleCids', 'reportVisibleCids', 0],
  ['accuracy', 'reportAccuracy', 0],
  ['motion', 'reportMotion', 0],
  ['powerLine', 'reportPowerLine', 0],
  ['batteryLevel', 'reportBatteryLevel', 0],
  ['protocol', 'reportProtocol', 0],
  ['message', 'reportMessage', 0],
];

const RouteReportPage = () => {
  const t = useTranslation();
  const classes = useReportStyles();
  const speedUnit = useAttributePreference('speedUnit');
  const coordinateFormat = usePreference('coordinateFormat');
  const [offColumns, setOffColumns] = usePersistedState(typeReport, []);
  const [devicesList, setDevicesList] = useState([]);
  const [devicesObject, setDevicesObject] = useState({});
  const devicesObjectRef = useRef(devicesObject);
  const [stateSyncDevices, setStateSyncDevices] = useState(new Date().getTime());
  const [lastSelected, setLastSelected] = useState({});

  const groups = useSelector((state) => state.groups.items);

  const history = useHistory();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [progress, setProgress] = useState(0);
  const [progressDevices, setProgressDevices] = useState(false);
  const [selectedItems, setSelectedItems] = useState(null);
  const [page, setPage] = useState(0);
  const [countProgress, setCountProgress] = useState(0);
  const [progressReport, setProgressReport] = useState(0);
  const [periodChosen, setPeriodChosen] = useState(false);
  const reportAbortController = useRef();

  useEffect(() => {
    const result = {};
    devicesList.forEach((item) => {
      result[item.id] = item;
      result[item.id].attributes = {};
    });
    setDevicesObject(result);
  }, [devicesList]);

  const columnsArrayFiltered = columnsArray.filter((column) => !offColumns.includes(column[0]));

  const handleSubmit = (deviceId, from, to, headers) => {
    const requestName = uuidv4();
    reportAbortController.current = new AbortController();
    const { signal } = reportAbortController.current;

    const apiCall = async () => {
      try {
        const query = new URLSearchParams({
          from, to,
        });
        deviceId.forEach((it) => query.append('deviceId', it));
        columnsArrayFiltered.forEach((it) => query.append('column', it[0]));
        query.append('requestName', requestName);
        setProgress(1);
        const response = await fetch(`/api/reports/route?${query.toString()}`, { headers, signal });
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType) {
            if (contentType === 'application/json') {
              setPage(0);
              const result = [];
              await collectReport(response, result, setProgressReport);
              for (const item of result) {
                item.categoryFromPosition = 'z';
              }
              setItems(result);
            } else {
              window.location.assign(window.URL.createObjectURL(await response.blob()));
            }
          }
        } else if (response.status === 401) {
          logout(history, dispatch);
        }
        setProgress(0);
      } catch (error) {
        if (error.name !== 'AbortError') {
          dispatch(errorsActions.push(error.message));
        } else {
          await abortRequest(requestName);
        }
        setProgress(0);
      }
    };
    apiCall();
  };

  const formatValue = (device, key, positions) => {
    switch (key) {
      case 'serverTime':
      case 'fixTime':
        return formatDate(device[key]);
      case 'deviceId':
        return positions[device[key]]?.name;
      case 'group':
        return groups[positions[device.deviceId]?.groupId]?.name || '';
      // Столбец категории
      case 'categoryFromPosition':
        return getCategoryName({ category: positions[device.deviceId]?.category }, t);
      case 'altitude':
        return tryConvertAndFixedNumber(device?.altitude);
      case 'longitude':
      case 'latitude':
        return formatPosition(device[key], key, t, null, coordinateFormat);
      case 'address':
        return formatPosition(device[key], 'address', t);
      case 'valid':
        return device.valid ? t('sharedValid') : t('sharedNotValid');
      case 'speed':
        return formatSpeed(device[key], speedUnit, t);
      case 'sat':
        return device.attributes[key] !== 99 ? device.attributes[key] : '';
      case 'visibleCids':
        try {
          if (isUndefinedOrNull(device.network?.cellTowers)) {
            return '';
          }
          if (device.network?.cellTowers.length > 1) {
            return device.network.cellTowers.slice(1).map((tower) => (tower.cellId)).join(',');
          }
          return device.network.cellTowers[0];
        } catch (error) {
          console.log(error);
          return '';
        }
      case 'lacCid':
        return device.network?.cellTowers.map((tower) => `${tower.locationAreaCode}-${tower.cellId}`)[0];
      case 'mccMnc':
        return device.network?.cellTowers
          .map((tower, index) => (index === 0 && `${tower.mobileCountryCode}-${tower.mobileNetworkCode}`))[0];
      case 'motion':
        return formatBoolean(device.attributes[key], t);
      case 'powerLine':
      case 'message':
        return device.attributes[key] || '';
      case 'accuracy':
        return formatPosition(device[key], 'accuracy', t);
      case 'batteryLevel':
        return formatPosition(device.attributes[key], 'batteryLevel', t);
      default:
        return device[key];
    }
  };

  useEffect(() => {
    if (periodChosen) {
      const abortController = new AbortController();
      const { signal } = abortController;

      const apiCall = async () => {
        const requestName = uuidv4();
        try {
          setProgressDevices(true);
          const response = await fetch(`/api/devices/stream?requestName=${requestName}`, { signal });
          if (response.ok) {
            const result = [];
            await collectReport(response, result, setCountProgress);
            setDevicesList(result);
            // setDevicesObject(result);
          } else if (response.status === 401) {
            if (response.status === 401) {
              logout(history, dispatch);
            }
          }
          setProgressDevices(false);
        } catch (error) {
          if (error.name !== 'AbortError') {
            dispatch(errorsActions.push(error.message));
          } else {
            await abortRequest(requestName);
          }
          setProgressDevices(false);
        }
      };
      apiCall();
      return () => {
        abortController.abort(); // Cancel the request if component unmounts
      };
    }
    return null;
  }, [periodChosen]);

  useEffect(() => () => {
    if (reportAbortController.current) {
      reportAbortController.current.abort(); // Cancel the request
    }
  }, []);

  useEffect(() => runLoadingResources(), []);

  useEffect(() => {
    devicesObjectRef.current = devicesObject;
    setStateSyncDevices(new Date().getTime());
  }, [devicesObject]);

  const handleDataSelect = (keys) => {
    const result = {};

    if (keys.length === 0) {
      setSelectedItems({});
      setLastSelected(null);
      return;
    }

    keys.forEach(async (item) => {
      const { deviceIntId } = item;

      if (!result[deviceIntId]) {
        result[deviceIntId] = [];
      }

      result[deviceIntId].push({
        deviceId: deviceIntId,
        course: item.course,
        latitude: parseFloat(item.latitude),
        longitude: parseFloat(item.longitude),
        attributes: {},
      });

      // Проверяем цвет и иконку
      if (!devicesObjectRef.current[deviceIntId]?.icon || !devicesObjectRef.current[deviceIntId]?.attributes?.color) {
        try {
          const response = await fetch(`/api/devices/${deviceIntId}`);
          if (response.ok) {
            const data = await response.json();
            setDevicesObject((prevState) => {
              const updatedState = {
                ...prevState,
                [deviceIntId]: data,
              };
              devicesObjectRef.current = updatedState;
              return updatedState;
            });
          } else if (response.status === 401) {
            logout(history, dispatch);
          }
        } catch (error) {
          console.error(`Failed to fetch device data for ID ${deviceIntId}:`, error);
        }
      }
    });

    setSelectedItems(result);
    setLastSelected(keys[keys.length - 1]);
  };

  return (
    <ReportTemplate
      items={items}
      progress={progress}
      progressDevices={progressDevices}
      setSelectedItems={setSelectedItems}
      handleDataSelect={handleDataSelect}
      columnsArray={columnsArray}
      handleSubmit={handleSubmit}
      formatValue={formatValue}
      page={page}
      typeReport={typeReport}
      offColumns={offColumns}
      setOffColumns={setOffColumns}
      breadcrumbs={['reportTitle', 'reportRoute']}
      devicesList={devicesList}
      devicesObject={devicesObjectRef.current}
      countProgress={countProgress}
      progressReport={progressReport}
      periodChosen={periodChosen}
      setPeriodChosen={setPeriodChosen}
      mapOn
      map={selectedItems && (
        <div className={classes.containerMap}>
          <Map noFixed>
            <PositionsMap
              positions={selectedItems}
              devices={devicesObjectRef.current}
              data={positionsTypes.current}
              stateSyncDevices={stateSyncDevices}
              noClick
            />
            {lastSelected && <MapCamera latitude={lastSelected.latitude} longitude={lastSelected.longitude} deviceId={`${lastSelected.latitude}-${lastSelected.longitude}`} />}
          </Map>
        </div>
      )}
    />
  );
};

export default RouteReportPage;
