import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawerContainer: {
    width: theme.dimensions.drawerWidthDesktop,
  },
  drawer: {
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: theme.dimensions.drawerWidthTablet,
    },
  },
  deviceSkeleton: {
    width: '100%',
    height: '90%',
    borderRadius: '5px',
    boxShadow: '0 -200px 100px -120px #e4f1fe inset',
    animation: '$background 3s infinite alternate',
  },
  '@keyframes background': {
    '50%': {
      boxShadow: '0 -200px 100px -100px #8dc6ff inset',
    },
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  containerMap: {
    flexBasis: '40%',
    flexShrink: 0,
  },
  containerMain: {
    overflow: 'auto',
    // padding: theme.spacing(5, 3, 3, 3),
  },
  header: {
    position: 'sticky',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: theme.spacing(0, 1, 0, 1),
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  backArrowIconContainer: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toolbar: {
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.toolbar,
    },
  },
  buttonHome: {
    marginLeft: 'auto',
  },
  menuEditRemove: {
    zIndex: -1,
    position: 'relative',
    maxHeight: '70vh',
  },
  superCheckbox: {
    padding: theme.spacing(0.5),
  },
  columnAction: {
    width: '1%',
    paddingLeft: theme.spacing(1),
  },
  filter: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2, 2),
  },
  filterItem: {
    minWidth: 0,
    flex: `1 1 ${theme.dimensions.filterFormWidth}`,
  },
  filterButtons: {
    display: 'flex',
    gap: theme.spacing(1),
    flex: `1 1 ${theme.dimensions.filterFormWidth}`,
  },
  filterButton: {
    flexGrow: 1,
  },
  filterWideButton: {
    flexGrow: 2,
  },
  field: {
    whiteSpace: 'nowrap',
  },
  iconSorting: {
    opacity: 0.2,
    '&:hover': {
      opacity: 1,
    },
  },
  iconSortingReverse: {
    transform: 'rotate(180deg)',
  },
  dataGridContainer: {
    height: '90vh',
  },
  dataGridContainerWithMap: {
    height: 'calc(90vh - 410px)',
  },
}));
