import React, { useRef, useEffect, useState } from 'react';
import 'video.js/dist/video-js.css';
import JSMpeg from '@cycjimmy/jsmpeg-player';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from '../LocalizationProvider';
import LoadingText from '../LoadingText';

const useStyles = makeStyles(() => ({
  hide: {
    display: 'none',
  },
  fullSize: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  aspectRatio: {
    position: 'relative',
    aspectRatio: '16 / 9',
  },
  responsive: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  errorArea: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 0,
  },
}));

const VideoJsMPEGController = ({
  url, responsive = false,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const videoWrapperRef = useRef(null);
  const canvasRef = useRef(null);
  const isLoadingState = useRef(null);
  const canvasContainerRef = useRef(null);

  useEffect(() => {
    isLoadingState.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    let videoElement = null;
    const videoWrapper = videoWrapperRef.current;
    const canvasContainer = canvasContainerRef.current;

    setIsLoading(true);

    try {
      // Создаем новый canvas каждый раз
      const newCanvas = document.createElement('canvas');
      if (canvasContainer) {
        canvasContainer.innerHTML = ''; // Очищаем контейнер
        canvasContainer.appendChild(newCanvas); // Добавляем новый canvas
        newCanvas.className = classes.fullSize;
        canvasRef.current = newCanvas;
      }

      videoElement = new JSMpeg.VideoElement(
        videoWrapper,
        url,
        { canvas: newCanvas },
        {
          protocols: [],
          audio: false,
          disableGl: true,
          disableWebAssembly: true,
          control: false,
          videoBufferSize: 1024 * 1024 * 4,
          onVideoDecode: () => {
            if (isLoadingState.current) {
              setIsLoading(false);
            }
          },
        },
      );
    } catch (error) {
      console.error(error);
      setIsError(true);
    }

    return () => {
      if (videoElement) {
        try {
          videoElement.destroy();
          setIsLoading(true);
        } catch (e) {
          console.log(e);
        }
      }
    };
  }, [url]);

  const containerClass = responsive ? classes.responsive : classes.aspectRatio;

  return (
    <div className={containerClass}>
      {!isError && isLoading && (
        <div className={containerClass}>
          <Skeleton variant="rect" width="100%" height="100%" />
          <pre className={classes.loadingText}>
            <LoadingText text={t('globalConnection')} />
          </pre>
        </div>
      )}
      {isError && (
        <div className={classes.errorArea}>{t('globalFailedToUpload')}</div>
      )}

      <div className={`${isLoading && classes.hide}`}>
        <div ref={canvasContainerRef} />
        <div ref={videoWrapperRef} />
      </div>
    </div>
  );
};

export default VideoJsMPEGController;
