import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  Button, LinearProgress, Typography, makeStyles,
} from '@material-ui/core';
import PageLayout from '../../../common/components/PageLayout';
import ReportsMenu from '../components/ReportsMenu';
import 'react-calendar-timeline/lib/Timeline.css';
import { useEffectAsync } from '../../../common/utils/reactHelper';
import CameraTimeline from './CameraTimeline';
import CameraVideoPlayer from './CameraVideoPlayer';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import scrollStyles from '../../../common/theme/scrollStyles';
import { DELIMITER } from './constants';
import { concatenateWithSeparator } from '../../../common/utils/stringUtils';
import ControlsPanel from './ControlsPanel';
import CameraBlocksContainer, { gridModeEnum } from '../components/CameraBlocksContainer';
import CameraBlock from '../components/CameraBlock';
import M3U8 from '../../../common/utils/m3u8';
import { minutesToMilliseconds } from '../../../common/utils/datetimeHelper';
import CameraActionsWindows from '../components/CameraActionsWindows';
import EventReviewLayer from '../components/EventReviewLayer';
import CameraLayer from '../components/CameraLayer';

const useStyles = makeStyles(() => ({
  scroll: {
    ...scrollStyles(3),
  },
  rightPanel: {
    backgroundColor: '#ffffff',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    paddingTop: 5,
  },
  noData: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  leftPanelContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 290px)',
    height: '100%',
  },
  cameraBlocksContainer: {
    width: '100%',
    height: '100px',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: 0,
    margin: 0,
    ...scrollStyles(3),
  },
  cameraBlocksContainerGrid: {
    gap: 1,
  },
  cameraVideoPlayerContainer: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    ...scrollStyles(3),
  },
  cameraTimelineContainer: {
    overflow: 'auto',
    width: '100%',
    height: '219px',
    ...scrollStyles(3),
  },
  rightPanelContainer: {
    width: '290px',
    ...scrollStyles(3),
  },
}));

const breadcrumbs = ['reportTitle', 'reportVideoArchive'];

const CameraRecordReportPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [camerasIds, setCamerasIds] = useState([]);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [videoData, setVideoData] = useState(undefined);
  const [eventReviews, setEventReviews] = useState([]);

  const timeline = useRef({ from: moment(), to: moment() });
  const camerasDataset = useRef({});
  const groups = useRef([]);

  const cameraLayer = CameraLayer({});
  const eventReviewLayer = EventReviewLayer({ cameraLayerRef: cameraLayer, eventReviewLimit: 15 });

  const createTimelineRecord = (cameraId, groupId, hourKey, currentHourRecords) => {
    const startTimeLocal = moment.unix(currentHourRecords[0].startTimeUnix).format('HH:mm');
    const endTimeLocal = moment.unix(currentHourRecords[currentHourRecords.length - 1].endTimeUnix).format('HH:mm');
    const localTime = concatenateWithSeparator('-', [startTimeLocal, endTimeLocal]);

    const { id, cameraName, deviceName } = cameraLayer.getCamerasMetadata()[cameraId];

    const record = ({
      id: concatenateWithSeparator(DELIMITER, [id, hourKey]),
      title: localTime,
      label: `${cameraName} (${deviceName}) ${localTime}`,
      // eslint-disable-next-line object-shorthand
      group: groupId,
      start_time: moment.unix(currentHourRecords[0].startTimeUnix).toDate(),
      end_time: moment.unix(currentHourRecords[currentHourRecords.length - 1].endTimeUnix).toDate(),
      startTimeUnix: currentHourRecords[0].startTimeUnix,
      endTimeUnix: currentHourRecords[currentHourRecords.length - 1].endTimeUnix,
    });
    return record;
  };

  const getGroupedRecordsByTime = (data) => {
    const groupedRecords = {};
    data.forEach((record) => {
      const startTime = moment.unix(record.start_time);
      const endTime = moment.unix(record.end_time);

      if (startTime.isValid() && endTime.isValid()) {
        const hourKey = startTime.format('YYYY-MM-DD HH');

        if (!groupedRecords[hourKey]) {
          groupedRecords[hourKey] = [];
        }

        groupedRecords[hourKey].push({
          ...record,
          start_time: startTime.toDate(),
          end_time: endTime.toDate(),
          startTimeUnix: startTime.unix(),
          endTimeUnix: endTime.unix(),
          duration: endTime.unix() - startTime.unix(),
        });
      } else {
        console.warn(`Некорректная дата для записи: ${record.start_time} или ${record.end_time}`);
      }
    });
    return groupedRecords;
  };

  const getCameraTimeRecords = async (cameraId, groupId, after, before) => {
    const TIME_DURATION_LIMIT = 3600; // 1 час

    const { cameraName, videoRecordService } = cameraLayer.getCamerasMetadata()[cameraId];
    // Длительность фрагмента видео, который добавляется на временную линию
    const response = await fetch(`${videoRecordService}/api/${cameraName}/recordings?after=${after}&before=${before}`);
    const data = await response.json();
    // Фрагменты видео сгруппированные по времени (1 часу)
    const groupedRecords = getGroupedRecordsByTime(data);
    // Массив элементов содержащий минимальный и максимальный временной диапазон для временной линии
    // Также содержит название и метаданные для получения видео от сервиса
    const summaryRecords = [];

    for (const hourKey in groupedRecords) {
      if (groupedRecords.hasOwnProperty(hourKey)) {
        const recordsForHour = groupedRecords[hourKey];

        // Сортируем записи в пределах часа по времени начала
        recordsForHour.sort((a, b) => a.startTimeUnix - b.startTimeUnix);

        let currentHourStart = moment(hourKey, 'YYYY-MM-DD HH');
        let currentHourEnd = moment(hourKey, 'YYYY-MM-DD HH').add(1, 'hour');
        let currentHourDuration = 0;
        let currentHourRecords = [];

        recordsForHour.forEach((record) => {
          if (record.endTimeUnix <= currentHourEnd.unix()) {
            // Запись полностью в пределах часа
            currentHourDuration += record.duration;
            currentHourRecords.push(record);
          } else if (record.startTimeUnix >= currentHourStart.unix() && record.startTimeUnix < currentHourEnd.unix()) {
            // Запись начинается в текущем часу, но заканчивается позже
            const durationInCurrentHour = currentHourEnd.unix() - record.startTimeUnix;
            currentHourDuration += durationInCurrentHour;
            currentHourRecords.push({
              ...record,
              end_time: currentHourEnd.toDate(),
              endTimeUnix: currentHourEnd.unix(),
              duration: durationInCurrentHour,
            });
          }

          if (currentHourDuration >= TIME_DURATION_LIMIT) {
            // Достигнут лимит 1 часа, добавляем запись в summaryRecords
            summaryRecords.push(createTimelineRecord(cameraId, groupId, hourKey, currentHourRecords));

            // Переходим к следующему часу
            currentHourStart = currentHourEnd;
            currentHourEnd = currentHourEnd.add(1, 'hour');
            currentHourDuration = 0;
            currentHourRecords = [];
          }
        });

        // Проверяем, остались ли записи в currentHourRecords
        if (currentHourRecords.length > 0) {
          summaryRecords.push(createTimelineRecord(cameraId, groupId, hourKey, currentHourRecords));
        }
      }
    }
    return summaryRecords;
  };

  const reloadCamerasTimeRecords = async () => {
    if (selectedCameras.length === 0) {
      return;
    }

    setIsLoading(true);
    groups.current.length = 0;
    camerasDataset.current = {};

    try {
      const from = timeline.current.from.unix();
      const to = timeline.current.to.unix();

      for (const cameraId of selectedCameras) {
        const { cameraName, nameDisplayed } = cameraLayer.getCamerasMetadata()[cameraId];
        const groupId = groups.current.length + 1;

        try {
          // eslint-disable-next-line no-await-in-loop
          const data = await getCameraTimeRecords(cameraId, groupId, from, to);
          camerasDataset.current[cameraId] = data;

          groups.current.push({ id: groupId, title: nameDisplayed });
        } catch (error) {
          console.warn(`Не удалось получить массив данных для камеры ${cameraName}.`, error);
        }
      }
    } catch (error) {
      console.error('Возникла ошибка при обработке массива камер.', error);
      setIsError(true);
    }

    setIsLoading(false);
  };

  const loadEventReviewsAndCameras = async () => {
    try {
      await eventReviewLayer.loadEventReviews();

      setCamerasIds(cameraLayer.getCameraUniqueIds());
      setEventReviews(eventReviewLayer.getEventReviews());
      if (isError) {
        setIsError(false);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  useEffect(() => {
    const id = setInterval(loadEventReviewsAndCameras, minutesToMilliseconds(1));
    return () => clearInterval(id);
  }, []);

  useEffectAsync(async () => {
    setIsLoading(true);
    await loadEventReviewsAndCameras();
    setIsLoading(false);
  }, []);

  const acceptDeleteConfirmWindow = async (item) => {
    try {
      const { videoRecordService, id } = item;
      const url = `${videoRecordService}/api/reviews/delete`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ids: [id],
        }),
      });

      if (response.ok) {
        setEventReviews((prev) => {
          const newVideos = prev.filter((v) => v.id !== id);
          return newVideos;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cameraActionsWindows = CameraActionsWindows({
    onDeleteConfirm: acceptDeleteConfirmWindow,
    type: 'application/x-mpegURL',
    additionalOptions: {
      disablePictureInPicture: true,
      controlBar: {
        skipButtons: {
          backward: 5,
          forward: 5,
        },
      },
    },
  });

  const handleRemoveVideo = (videoItem) => {
    cameraActionsWindows.setDataForWindows(videoItem);
    cameraActionsWindows.openDeleteConfirmWindow();
  };

  const handlePlayVideo = (videoItem) => {
    cameraActionsWindows.setDataForWindows({
      ...videoItem,
      srcUrl: videoItem.videoUrl,
    });
    cameraActionsWindows.openVideoPreviewWindow();
  };

  const handleSaveVideo = async (videoItem, startDownload, endDownload) => {
    startDownload();

    try {
      const m3u8 = new M3U8();
      const download = m3u8.start(videoItem.videoUrl, { filename: `${videoItem.text}.mp4` });
      download.on('finished', endDownload).on('error', endDownload).on('aborted', endDownload);
    } catch (error) {
      console.error(error);
    }
  };

  if (isError) {
    return (
      <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
        <div style={{ flexDirection: 'column' }} className={classes.noData}>
          <div>{t('globalErrorOccurredReceiving')}</div>
          <Button disabled={isLoading} variant="outlined" color="primary" onClick={loadEventReviewsAndCameras}>
            {t('globalAgain')}
          </Button>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={breadcrumbs}>
      <div className={classes.mainContainer}>
        <div className={classes.leftPanelContainer}>
          {Object.keys(camerasDataset.current).length === 0 ? (
            <>
              {isLoading ? (<LinearProgress />) : (
                <div className={classes.noData}>
                  <Typography variant="h6">{t('globalNoDataToDisplay')}</Typography>
                </div>
              )}
            </>
          ) : (
            <>
              {cameraActionsWindows.ActionsWindows}

              <CameraBlocksContainer
                containerClass={classes.cameraBlocksContainer}
                gridClass={classes.cameraBlocksContainerGrid}
                gridMode={gridModeEnum.Horizontal}
              >
                {eventReviews
                  .map((eventReviewItem) => (
                    <CameraBlock
                      key={eventReviewItem.id}
                      smallButtons
                      disableBorder
                      inLineButtons
                      cameraItem={{
                        ...eventReviewItem,
                        id: eventReviewItem.id,
                        srcUrl: eventReviewItem.thumbUrl,
                        leftText: eventReviewItem.camera,
                        rightText: moment.unix(eventReviewItem.start_time).format('HH:mm:ss'),
                      }}
                      cameraBlockContainerStyle={{ aspectRatio: '18 / 9' }}
                      onPlayVideo={handlePlayVideo}
                      onRemoveVideo={handleRemoveVideo}
                      onSaveVideo={handleSaveVideo}
                    />
                  ))}
              </CameraBlocksContainer>

              <div className={classes.cameraVideoPlayerContainer}>
                <CameraVideoPlayer videoData={videoData} />
              </div>

              <div className={classes.cameraTimelineContainer}>
                <CameraTimeline
                  camerasDataset={camerasDataset.current}
                  camerasMetadata={cameraLayer.getCamerasMetadata()}
                  groups={groups.current}
                  timeline={timeline.current}
                  setVideoData={setVideoData}
                />
              </div>
            </>
          )}
        </div>

        <div className={classes.rightPanelContainer}>
          <div className={classes.rightPanel}>
            <ControlsPanel
              classes={classes}
              camerasIds={camerasIds}
              camerasMetadata={cameraLayer.getCamerasMetadata()}
              isLoading={isLoading}
              timeline={timeline.current}
              reloadCamerasTimeRecords={reloadCamerasTimeRecords}
              selectedCameras={selectedCameras}
              setSelectedCameras={setSelectedCameras}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default CameraRecordReportPage;
