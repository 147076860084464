import React, {
  useRef, useMemo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { DeleteOutline } from '@material-ui/icons';
import {
  DataGrid,
} from 'devextreme-react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  ColumnChooser, ColumnFixing, FilterRow, GroupPanel, Grouping, LoadPanel, Pager,
  Paging, Position, StateStoring, Sorting, Column, Selection, FilterPanel, HeaderFilter,
} from 'devextreme-react/data-grid';
import { useSelector } from 'react-redux';
import {
  useMediaQuery, makeStyles, useTheme,
} from '@material-ui/core';
import ExportForm from './ExportForm';
import ResetForm from './ResetForm';
import { useTranslation } from '../LocalizationProvider';
import useDevextremeTablesSettings from '../../hooks/useDevextremeTablesSettings';
import TablesToolbar from './TablesToolbar';
import SettingsForm from './SettingsForm';
import { FILTER_SAVING, YES } from './constants';
import { isUndefinedOrNull } from '../../utils/stringUtils';
import { getPropertyOrDefaultValue, getYesOrNoPhrase, isTextEqualsYes } from './funcs/utils';
import { getIsAdmin } from '../../utils/selectors';

const useStyles = makeStyles((theme) => ({
  pointerCursor: {
    cursor: 'pointer',
  },
  dataGridContainer: {
    height: '100vh',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 80px)',
      margin: 0,
      marginTop: 20,
    },
  },
  dataGridContainerWithMap: {
    height: 'calc(100vh - 410px)',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 80px - 410px)',
      margin: 0,
      marginTop: 20,
    },
  },
  backgroundColor: {
    backgroundColor: 'rgb(255 92 77)',
  },
  maxWidth: {
    maxWidth: 150,
  },
}));

const defaultAllowedPageSizes = [10, 25];
const defaultPageSize = 25;
const defaultShowCheckBoxesMode = 'always';
const otherShowCheckBoxesMode = 'onClick';

const SmartTable = ({
  arrayDependencies = [], classes,
  tableName, fileExportingName,
  dataSource, children,
  disableExportTool, disableFilterRowTool,
  disableSavingFiltersSwitch = false,
  disableActionColumnIntegrated = false,
  disableAutoSaveSettings = false,
  enableAutoExpandAll = false, enableRepaintChangesOnly = false, enableSavingFilters = false,
  allowedPageSizes, currentPageSize,
  deviceStopFunction,
  onSelectingRows, onMenuClick, onAddNewRow,
  handleDataSelect, mapOn,
}) => {
  const innerClasses = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const tableSettings = useDevextremeTablesSettings(tableName ?? '');
  const isAdmin = useSelector(getIsAdmin);

  const selectedRowKeysCount = useRef([]);
  const dataGridRef = useRef();
  const init = useRef(false);
  const isSaveSettings = useRef(false);

  const [isShowExportingForm, setIsShowExportingForm] = useState(false);
  const [isShowToDefaultResetForm, setIsShowToDefaultResetForm] = useState(false);
  const [isShowFiltersInPanelAndHeaders, setIsShowFiltersInPanelAndHeaders] = useState(false);
  const [isShowSettingsForm, setIsShowSettingsForm] = useState(false);
  const [isFilterPanelEnabled, setIsFilterPanelEnabled] = useState(false);
  const [showCheckBoxesMode, setShowCheckBoxesMode] = useState(defaultShowCheckBoxesMode);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const containerClass = mapOn
    ? (classes?.dataGridContainerWithMap ?? innerClasses.dataGridContainerWithMap)
    : (classes?.dataGridContainer ?? innerClasses.dataGridContainer);

  const openResetForm = () => setIsShowToDefaultResetForm(true);

  const deleteFields = useCallback((newValue) => {
    delete newValue.selectedRowKeys;
    delete newValue.filterPanel;
    delete newValue.filterValue;

    newValue.columns.forEach((column) => {
      delete column.filterValue;
      delete column.sortIndex;
      delete column.sortOrder;
      delete column.lastSortOrder;
      delete column.groupIndex;
      delete column.selectedFilterOperation;
    });
  }, []);

  const clearFiltersIfRequired = (object) => {
    if (isShowFiltersInPanelAndHeaders) {
      return;
    }

    // Удаляет свойства из настроек Datagrid, если сохранение настроек отключено.
    if (!isUndefinedOrNull(object) && !isSaveSettings.current) {
      deleteFields(object);
    }
  };

  const saveCurrentDataGridState = () => {
    const state = dataGridRef.current.instance.state();
    isSaveSettings.current = true;
    state[FILTER_SAVING] = YES;
    tableSettings.save(state);
  };

  const customLoad = useCallback(() => {
    if (init.current) {
      return false;
    }

    const settings = tableSettings.get();
    // Если настройки не определены и включено сохранение настроек.
    // То получает и задает настройкам Datagrid параметры сохранения.
    // Это необходимо для создание настроек в первый раз, когда включается сохранение в UI.
    if (isUndefinedOrNull(settings) && enableSavingFilters) {
      setTimeout(saveCurrentDataGridState, 300);
    }

    if (isUndefinedOrNull(settings)) {
      return false;
    }

    init.current = true;

    // Определяем включено ли сохранение настроек.
    const filterSavingValue = getPropertyOrDefaultValue(settings, FILTER_SAVING);
    if (!isUndefinedOrNull(filterSavingValue)) {
      isSaveSettings.current = isTextEqualsYes(filterSavingValue);
    }

    if (!isSaveSettings.current) {
      return false;
    }

    if (settings?.filterPanel?.filterEnabled || (!isUndefinedOrNull(settings?.filterValue) && settings?.filterValue.length)) {
      setIsShowFiltersInPanelAndHeaders(true);
    }

    return settings;
  }, []);

  const customSave = useCallback((value) => {
    const newValue = { ...value };

    newValue[FILTER_SAVING] = getYesOrNoPhrase(isSaveSettings.current);
    clearFiltersIfRequired(newValue);
    tableSettings.save(newValue);
  }, []);

  const handleDeleteAll = () => {
    if (selectedRowKeysCount.current.length === 0) {
      return;
    }
    onMenuClick(null, selectedRowKeysCount.current);
  };

  const handleSelectedRowKeysChange = (keys) => {
    if (handleDataSelect) {
      handleDataSelect(keys);
    }
    selectedRowKeysCount.current = keys;
    if (onSelectingRows) {
      onSelectingRows(keys);
    }
  };

  const actionsMenuRender = useCallback(({ data }) => (
    <MoreVertIcon className={innerClasses.pointerCursor} color="action" onClick={(e) => onMenuClick(e.currentTarget, data.data.id)} />
  ), []);

  const headerActionsMenuRender = useCallback(() => (
    <DeleteOutline
      className={innerClasses.pointerCursor}
      color="action"
      onClick={handleDeleteAll}
    />
  ), []);

  // Позволяет devextreme увидеть свои компоненты и применить
  const tablesToolbar = TablesToolbar({
    t,
    disableExportTool,
    deviceStopFunction,
    isMobile,
    isShowFiltersInPanelAndHeaders,
    setIsShowExportingForm,
    setIsShowFiltersInPanelAndHeaders,
    setIsShowSettingsForm,
    onAddNewRow,
  });

  // Исправляет с некорректным отображением высоты ячейки флажка при переключении панели фильтров
  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowCheckBoxesMode(() => otherShowCheckBoxesMode);
      setShowCheckBoxesMode(() => defaultShowCheckBoxesMode);
    }, 50);

    return () => {
      clearTimeout(timerId);
    };
  }, [isShowFiltersInPanelAndHeaders]);

  return (
    <>
      {useMemo(() => (
        <DataGrid
          ref={dataGridRef}
          dataSource={dataSource}
          className={`dx-card wide-card ${containerClass}`}
          showBorders={false}
          columnResizingMode="widget"
          hoverStateEnabled
          repaintChangesOnly={enableRepaintChangesOnly}
          highlightChanges={enableRepaintChangesOnly}
          allowColumnReordering
          allowColumnResizing
          columnAutoWidth
          onSelectedRowKeysChange={handleSelectedRowKeysChange}
        >
          <StateStoring
            type="custom"
            enabled={!disableAutoSaveSettings}
            // enabled={false}
            customSave={customSave}
            customLoad={customLoad}
            savingTimeout={150}
          />
          <ColumnChooser enabled mode="select" allowSearch height={400} width={300}>
            <Position
              my="right top"
              at="right top"
              of=".dx-datagrid-column-chooser-button"
            />
          </ColumnChooser>
          <LoadPanel enabled={false} />
          <ColumnFixing enabled />
          <Paging enabled defaultPageSize={currentPageSize ?? defaultPageSize} />
          <Pager
            visible
            showPageSizeSelector
            showInfo
            showNavigationButtons
            allowedPageSizes={allowedPageSizes ?? defaultAllowedPageSizes}
          />

          {/* Строки ввода для фильтрации в столбцах */}
          <FilterRow visible={!disableFilterRowTool} />
          {/* Строка снизу отображающая примененные фильтры */}
          <FilterPanel visible={isShowFiltersInPanelAndHeaders} filterEnabled={isFilterPanelEnabled} onFilterEnabledChange={setIsFilterPanelEnabled} />
          {/* Иконки фильтрации для столбцов */}
          <HeaderFilter visible={isShowFiltersInPanelAndHeaders} allowSearch />

          <Selection mode="multiple" showCheckBoxesMode={showCheckBoxesMode} />
          <Grouping contextMenuEnabled autoExpandAll={enableAutoExpandAll} expandMode="rowClick" />
          <GroupPanel visible />
          <Sorting mode="multiple" />

          {tablesToolbar}

          {(onMenuClick && !disableActionColumnIntegrated) && (
            <Column
              key="globalActions"
              caption={t('globalActions')}
              allowExporting={false}
              allowFiltering={false}
              allowGrouping={false}
              headerCellComponent={isAdmin && headerActionsMenuRender}
              cellComponent={actionsMenuRender}
              width={40}
            />
          )}

          {children}
        </DataGrid>
      ), [...arrayDependencies, isMobile, isShowFiltersInPanelAndHeaders, showCheckBoxesMode, isFilterPanelEnabled])}

      {useMemo(() => (
        <ResetForm
          isShowToDefaultResetForm={isShowToDefaultResetForm}
          setIsShowToDefaultResetForm={setIsShowToDefaultResetForm}
          devicesTableSettings={tableSettings}
        />
      ), [isShowToDefaultResetForm])}

      {useMemo(() => (
        <ExportForm
          setIsShowExportingForm={setIsShowExportingForm}
          dataGridRef={dataGridRef}
          isShowExportingForm={isShowExportingForm}
          fileExportingName={fileExportingName}
          isMobile={isMobile}
        />
      ), [isShowExportingForm])}

      {useMemo(() => (
        <SettingsForm
          isShowSettingsForm={isShowSettingsForm}
          setIsShowSettingsForm={setIsShowSettingsForm}
          isMobile={isMobile}
          openResetForm={openResetForm}
          tableSettings={tableSettings}
          isSaveSettings={isSaveSettings}
          enableSavingFilters={enableSavingFilters}
          dataGridRef={dataGridRef}
          clearFiltersIfRequired={clearFiltersIfRequired}
          disableSavingFiltersSwitch={disableSavingFiltersSwitch}
        />
      ), [isShowSettingsForm])}
    </>
  );
};

export default SmartTable;

SmartTable.propTypes = {
  onMenuClick: PropTypes.func,
  onAddNewRow: PropTypes.func,
  tableName: PropTypes.string,
  dataSource: PropTypes.any,
  children: PropTypes.any,
  fileExportingName: PropTypes.string,
  disableExportTool: PropTypes.bool,
  disableAutoSaveSettings: PropTypes.bool,
  disableFilterRowTool: PropTypes.bool,
  disableActionColumnIntegrated: PropTypes.bool,
  enableAutoExpandAll: PropTypes.bool,
  enableRepaintChangesOnly: PropTypes.bool,
  enableSavingFilters: PropTypes.bool,
  allowedPageSizes: PropTypes.array,
  currentPageSize: PropTypes.number,
  arrayDependencies: PropTypes.array,
  classes: PropTypes.object,
  onSelectingRows: PropTypes.func,
  deviceStopFunction: PropTypes.func,
};
